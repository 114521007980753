<template>
  <div class="panel">
    <div>
      <el-date-picker
        size="mini"
        v-model="start_date"
        type="datetime"
        placeholder="Select date and time"
      >
      </el-date-picker>
      <el-date-picker
        size="mini"
        class="ml5"
        v-model="end_date"
        type="datetime"
        placeholder="Select date and time"
      >
      </el-date-picker>
      <el-button
        class="ml5"
        size="mini"
        type="primary"
        v-loading.fullscreen.lock="loading"
        @click="getOrdersDashboard"
        >Хайх</el-button
      >
      <el-button
        size="mini"
        type="success"
        @click="onexport(type)"
        style="float:right;"
        >Татаж авах(.xlsx)</el-button
      >
      <el-select
        size="mini"
        v-model="type"
        @change="getOrdersDashboard"
        style="float:right;"
      >
        <el-option label="Sales" value="sales"></el-option>
        <el-option label="TR" value="tr"></el-option>
      </el-select>
    </div>
    <div style="margin-top: 1%; height:100%">
      <el-row :gutter="20" class="mt10">
        <el-col :span="8">
          <div
            class="card-container"
            style="background-color:#ffb500; color:white"
          >
            <h1>TR: {{ dashboardData.tr }}</h1>
            <el-row :gutter="10">
              <el-col
                :style="dashboardData.y_tr < 0 ? 'color:red' : ''"
                :span="8"
                >Y to Y: {{ dashboardData.y_tr }} %</el-col
              >
              <el-col
                :style="dashboardData.m_tr < 0 ? 'color:red' : ''"
                :span="8"
                >M to M: {{ dashboardData.m_tr }} %</el-col
              >
              <el-col
                :style="dashboardData.w_tr < 0 ? 'color:red' : ''"
                :span="8"
                >W to W: {{ dashboardData.w_tr }} %</el-col
              >
            </el-row>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="card-container">
            <h1>
              Sales: {{ parseInt(dashboardData.sales).toLocaleString() }} ₮
            </h1>
            <el-row :gutter="10">
              <el-col
                :style="dashboardData.y_sales < 0 ? 'color:red' : ''"
                :span="8"
                >Y to Y: {{ dashboardData.y_sales }} %</el-col
              >
              <el-col
                :style="dashboardData.m_sales < 0 ? 'color:red' : ''"
                :span="8"
                >M to M: {{ dashboardData.m_sales }} %</el-col
              >
              <el-col
                :style="dashboardData.w_sales < 0 ? 'color:red' : ''"
                :span="8"
                >W to W: {{ dashboardData.w_sales }} %</el-col
              >
            </el-row>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="card-container">
            <h1>TA: {{ parseInt(dashboardData.ta).toLocaleString() }} ₮</h1>
            <el-row :gutter="10">
              <el-col
                :style="dashboardData.y_ta < 0 ? 'color:red' : ''"
                :span="8"
                >Y to Y: {{ dashboardData.y_ta }} %</el-col
              >
              <el-col
                :style="dashboardData.m_ta < 0 ? 'color:red' : ''"
                :span="8"
                >M to M: {{ dashboardData.m_ta }} %</el-col
              >
              <el-col
                :style="dashboardData.w_ta < 0 ? 'color:red' : ''"
                :span="8"
                >W to W: {{ dashboardData.w_ta }} %</el-col
              >
            </el-row>
          </div>
        </el-col>
      </el-row>
      <el-card v-if="dashboardData.delivery" style="margin-top:1%; width:100%;">
        <el-collapse>
          <el-collapse-item>
            <template slot="title">
              <h1>Үзүүлэлтүүд</h1>
            </template>
            <el-row :gutter="20" class="mt10">
              <el-col :span="8">
                <div class="card-container">
                  <h1>
                    Delivery:
                    {{
                      parseInt(
                        dashboardData.delivery.sales_del
                      ).toLocaleString()
                    }}
                    ₮
                  </h1>
                  <el-row :gutter="10">
                    <el-col
                      :style="
                        dashboardData.delivery.y_sales_del < 0
                          ? 'color:red'
                          : ''
                      "
                      :span="8"
                      >Y: {{ dashboardData.delivery.y_sales_del }} %</el-col
                    >
                    <el-col
                      :style="
                        dashboardData.delivery.m_sales_del < 0
                          ? 'color:red'
                          : ''
                      "
                      :span="8"
                      >M: {{ dashboardData.delivery.m_sales_del }} %</el-col
                    >
                    <el-col
                      :style="
                        dashboardData.delivery.w_sales_del < 0
                          ? 'color:red'
                          : ''
                      "
                      :span="8"
                      >W: {{ dashboardData.delivery.w_sales_del }} %</el-col
                    >
                  </el-row>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="card-container">
                  <h1>TR Delivery: {{ dashboardData.delivery.tr_del }}</h1>
                  <el-row :gutter="10">
                    <el-col
                      :style="
                        dashboardData.delivery.y_tr_del < 0 ? 'color:red' : ''
                      "
                      :span="8"
                      >Y: {{ dashboardData.delivery.y_tr_del }} %</el-col
                    >
                    <el-col
                      :style="
                        dashboardData.delivery.m_tr_del < 0 ? 'color:red' : ''
                      "
                      :span="8"
                      >M: {{ dashboardData.delivery.m_tr_del }} %</el-col
                    >
                    <el-col
                      :style="
                        dashboardData.delivery.w_tr_del < 0 ? 'color:red' : ''
                      "
                      :span="8"
                      >W: {{ dashboardData.delivery.w_tr_del }} %</el-col
                    >
                  </el-row>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="card-container">
                  <h1>
                    TA Delivery:
                    {{
                      parseInt(dashboardData.delivery.ta_del).toLocaleString()
                    }}
                    ₮
                  </h1>
                  <el-row :gutter="10">
                    <el-col
                      :style="
                        dashboardData.delivery.y_ta_del < 0 ? 'color:red' : ''
                      "
                      :span="8"
                      >Y: {{ dashboardData.delivery.y_ta_del }} %</el-col
                    >
                    <el-col
                      :style="
                        dashboardData.delivery.m_ta_del < 0 ? 'color:red' : ''
                      "
                      :span="8"
                      >M: {{ dashboardData.delivery.m_ta_del }} %</el-col
                    >
                    <el-col
                      :style="
                        dashboardData.delivery.w_ta_del < 0 ? 'color:red' : ''
                      "
                      :span="8"
                      >W: {{ dashboardData.delivery.w_ta_del }} %</el-col
                    >
                  </el-row>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="mt10">
              <el-col :span="8">
                <div class="card-container">
                  <h1>
                    NoN Delivery:
                    {{
                      parseInt(
                        dashboardData.non_delivery.sales_non_del
                      ).toLocaleString()
                    }}
                    ₮
                  </h1>
                  <el-row :gutter="10">
                    <el-col
                      :style="
                        dashboardData.non_delivery.y_sales_non_del < 0
                          ? 'color:red'
                          : ''
                      "
                      :span="8"
                      >Y:
                      {{
                        dashboardData.non_delivery.y_sales_non_del === null
                          ? 0
                          : dashboardData.non_delivery.y_sales_non_del
                      }}
                      %</el-col
                    >
                    <el-col
                      :style="
                        dashboardData.non_delivery.m_sales_non_del < 0
                          ? 'color:red'
                          : ''
                      "
                      :span="8"
                      >M:
                      {{ dashboardData.non_delivery.m_sales_non_del }} %</el-col
                    >
                    <el-col
                      :style="
                        dashboardData.non_delivery.w_sales_non_del < 0
                          ? 'color:red'
                          : ''
                      "
                      :span="8"
                      >W:
                      {{ dashboardData.non_delivery.w_sales_non_del }} %</el-col
                    >
                  </el-row>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="card-container">
                  <h1>
                    TR NoN Delivery: {{ dashboardData.non_delivery.tr_non_del }}
                  </h1>
                  <el-row :gutter="10">
                    <el-col
                      :style="
                        dashboardData.non_delivery.y_tr_non_del < 0
                          ? 'color:red'
                          : ''
                      "
                      :span="8"
                      >Y:
                      {{
                        dashboardData.non_delivery.y_tr_non_del === null
                          ? 0
                          : dashboardData.non_delivery.y_tr_non_del
                      }}
                      %</el-col
                    >
                    <el-col
                      :style="
                        dashboardData.non_delivery.m_tr_non_del < 0
                          ? 'color:red'
                          : ''
                      "
                      :span="8"
                      >M:
                      {{ dashboardData.non_delivery.m_tr_non_del }} %</el-col
                    >
                    <el-col
                      :style="
                        dashboardData.non_delivery.w_tr_non_del < 0
                          ? 'color:red'
                          : ''
                      "
                      :span="8"
                      >W:
                      {{ dashboardData.non_delivery.w_tr_non_del }} %</el-col
                    >
                  </el-row>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="card-container">
                  <h1>
                    TA NoN Delivery:
                    {{
                      parseInt(
                        dashboardData.non_delivery.ta_non_del
                      ).toLocaleString()
                    }}
                    ₮
                  </h1>
                  <el-row :gutter="10">
                    <el-col
                      :style="
                        dashboardData.non_delivery.y_ta_non_del < 0
                          ? 'color:red'
                          : ''
                      "
                      :span="8"
                      >Y:
                      {{
                        dashboardData.non_delivery.y_ta_non_del === null
                          ? 0
                          : dashboardData.non_delivery.y_ta_non_del
                      }}
                      %</el-col
                    >
                    <el-col
                      :style="
                        dashboardData.non_delivery.m_ta_non_del < 0
                          ? 'color:red'
                          : ''
                      "
                      :span="8"
                      >M:
                      {{ dashboardData.non_delivery.m_ta_non_del }} %</el-col
                    >
                    <el-col
                      :style="
                        dashboardData.non_delivery.w_ta_non_del < 0
                          ? 'color:red'
                          : ''
                      "
                      :span="8"
                      >W:
                      {{ dashboardData.non_delivery.w_ta_non_del }} %</el-col
                    >
                  </el-row>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="mt10">
              <el-col :span="8">
                <div class="card-container">
                  <h1>
                    Mall_Sales:
                    {{ parseInt(mallDashboardData.sales).toLocaleString() }} ₮
                  </h1>
                  <el-row :gutter="10">
                    <el-col
                      :style="mallDashboardData.y_sales < 0 ? 'color:red' : ''"
                      :span="8"
                      >Y: {{ mallDashboardData.y_sales }} %</el-col
                    >
                    <el-col
                      :style="mallDashboardData.m_sales < 0 ? 'color:red' : ''"
                      :span="8"
                      >M: {{ mallDashboardData.m_sales }} %</el-col
                    >
                    <el-col
                      :style="mallDashboardData.w_sales < 0 ? 'color:red' : ''"
                      :span="8"
                      >W: {{ mallDashboardData.w_sales }} %</el-col
                    >
                  </el-row>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="card-container">
                  <h1>Mall_TR: {{ mallDashboardData.tr }}</h1>
                  <el-row :gutter="10">
                    <el-col
                      :style="mallDashboardData.y_tr < 0 ? 'color:red' : ''"
                      :span="8"
                      >Y: {{ mallDashboardData.y_tr }} %</el-col
                    >
                    <el-col
                      :style="mallDashboardData.m_tr < 0 ? 'color:red' : ''"
                      :span="8"
                      >M: {{ mallDashboardData.m_tr }} %</el-col
                    >
                    <el-col
                      :style="mallDashboardData.w_tr < 0 ? 'color:red' : ''"
                      :span="8"
                      >W: {{ mallDashboardData.w_tr }} %</el-col
                    >
                  </el-row>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="card-container">
                  <h1>
                    Mall_TA:
                    {{ parseInt(mallDashboardData.ta).toLocaleString() }} ₮
                  </h1>
                  <el-row :gutter="10">
                    <el-col
                      :style="mallDashboardData.y_ta < 0 ? 'color:red' : ''"
                      :span="8"
                      >Y: {{ mallDashboardData.y_ta }} %</el-col
                    >
                    <el-col
                      :style="mallDashboardData.m_ta < 0 ? 'color:red' : ''"
                      :span="8"
                      >M: {{ mallDashboardData.m_ta }} %</el-col
                    >
                    <el-col
                      :style="mallDashboardData.w_ta < 0 ? 'color:red' : ''"
                      :span="8"
                      >W: <span>{{ mallDashboardData.w_ta }} %</span></el-col
                    >
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </el-collapse-item>
        </el-collapse>
      </el-card>
      <el-row :gutter="20" class="mt10">
        <el-col :span="8">
          <div
            class="card-container"
            style="background-color:#7cbb00; color:white"
          >
            <el-row
              :gutter="10"
              style="display: flex; justify-content: space-between;"
            >
              <div :style="dashboardData.avgDistance < 0 ? 'color:red' : ''">
                Distance: {{ dashboardData.avgDistance }} km
              </div>
              <div :style="dashboardData.avgTime < 0 ? 'color:red' : ''">
                Time: {{ dashboardData.avgTime }} мин
              </div>
            </el-row>
            <h1 :style="dashboardData.kpiCust < 0 ? 'color:red' : ''">
              Хэрэглэгч:
              <span>{{ dashboardData.kpiCust }}%</span>
            </h1>
            <el-row :gutter="10">
              <el-col
                :style="dashboardData.kpiCustYear < 0 ? 'color:red' : ''"
                :span="8"
                >Y to Y: {{ dashboardData.kpiCustYear }}%</el-col
              >
              <el-col
                :style="dashboardData.kpiCustMonth < 0 ? 'color:red' : ''"
                :span="8"
                >M to M: {{ dashboardData.kpiCustMonth }}%</el-col
              >
              <el-col
                :style="dashboardData.kpiCustWeek < 0 ? 'color:red' : ''"
                :span="8"
                >W to W: {{ dashboardData.kpiCustWeek }}%</el-col
              >
            </el-row>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="card-container">
            <el-row
              :gutter="10"
              style="display: flex; justify-content: space-between;"
            >
              <div :style="dashboardData.avgDistance < 0 ? 'color:red' : ''">
                Distance: {{ dashboardData.avgDistance }} km
              </div>
              <div :style="dashboardData.avgTimeClient < 0 ? 'color:red' : ''">
                Time: {{ dashboardData.avgTimeClient }} мин
              </div>
            </el-row>
            <h1 :style="dashboardData.kpiClient < 80 ? 'color:red' : ''">
              Харилцагч
              <span>{{ dashboardData.kpiClient }}%</span>
            </h1>
            <el-row :gutter="10">
              <el-col
                :style="dashboardData.kpiClientYear < 0 ? 'color:red' : ''"
                :span="8"
                >Y to Y: {{ dashboardData.kpiClientYear }}%</el-col
              >
              <el-col
                :style="dashboardData.kpiClientMonth < 0 ? 'color:red' : ''"
                :span="8"
                >M to M: {{ dashboardData.kpiClientMonth }}%</el-col
              >
              <el-col
                :style="dashboardData.kpiClientWeek < 0 ? 'color:red' : ''"
                :span="8"
                >W to W: {{ dashboardData.kpiClientWeek }}%</el-col
              >
            </el-row>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="card-container">
            <el-row
              :gutter="10"
              style="display: flex; justify-content: space-between;"
            >
              <div>
                Distance: 0 km
              </div>
              <div :style="dashboardData.avgTime < 0 ? 'color:red' : ''">
                Time: {{ dashboardData.avgTime }} мин
              </div>
            </el-row>
            <h1 :style="dashboardData.kpiRider < 0 ? 'color:red' : ''">
              Жолооч
              <span>{{ dashboardData.kpiRider }}%</span>
            </h1>
            <el-row :gutter="10">
              <el-col
                :style="dashboardData.kpiCustYear < 0 ? 'color:red' : ''"
                :span="8"
                >Y to Y: {{ dashboardData.kpiCustYear }}%</el-col
              >
              <el-col
                :style="dashboardData.kpiCustMonth < 0 ? 'color:red' : ''"
                :span="8"
                >M to M: {{ dashboardData.kpiCustMonth }}%</el-col
              >
              <el-col
                :style="dashboardData.kpiCustWeek < 0 ? 'color:red' : ''"
                :span="8"
                >W to W: {{ dashboardData.kpiCustWeek }}%</el-col
              >
            </el-row>
          </div>
        </el-col>
      </el-row>
      <div class="chartContainer mt10">
        <div class="minWidth">
          <el-card>
            Payment
            <apexchart
              type="pie"
              width="370"
              :options="chartPayment"
              :series="seriesPayment"
            ></apexchart>
          </el-card>
          <el-button
            style="width:100%"
            size="mini"
            type="success"
            icon="el-icon-download"
            @click="onexport('payment')"
            >Татаж авах</el-button
          >
        </div>
        <div class="minWidth">
          <el-card>
            TR
            <apexchart
              type="pie"
              width="320"
              :options="chartTR"
              :series="seriesTR"
            ></apexchart>
          </el-card>
          <el-button
            style="width:100%"
            size="mini"
            type="success"
            icon="el-icon-download"
            @click="onexport('category')"
            >Татаж авах</el-button
          >
        </div>
        <div class="minWidth">
          <el-card
            style="box-shadow: 5px 10px 8px #888888; height:35vh; overflow:scroll"
          >
            <el-row
              v-for="(time, index) in dashboardData.time_data"
              :key="index"
              style="border-radius: 10px; border-bottom: 1px solid lightgrey; border-top: 1px solid lightgrey;"
            >
              <el-col :span="8">
                <p>{{ time.time }}</p>
              </el-col>
              <el-col :span="8">
                <p><b>Sales: </b>{{ time.sales }}</p>
              </el-col>
              <el-col :span="8">
                <p><b>TR: </b>{{ time.tr }}</p>
              </el-col>
            </el-row>
          </el-card>
          <el-button
            style="width:100%"
            size="mini"
            type="success"
            icon="el-icon-download"
            @click="onexport('time')"
            >Татаж авах</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import service from "../helpers/services";
import XLSX from "xlsx";
export default {
  name: "dashboardReport",
  created() {
    this.getOrdersDashboard();
  },
  data() {
    return {
      dashboardData: {},
      mallDashboardData: {},
      start_date: dayjs()
        .add(-1, "day")
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .format("YYYY-MM-DD HH:mm:ss"),
      end_date: dayjs()
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .format("YYYY-MM-DD HH:mm:ss"),
      seriesTR: [],
      chartTR: {
        chart: {
          width: 380,
          type: "pie"
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480
          }
        ]
      },
      seriesPayment: [],
      chartPayment: {},
      loading: false,
      type: "sales"
    };
  },
  methods: {
    async getOrdersDashboard() {
      this.loading = true;
      this.chartTR = {
        chart: {
          width: 380,
          type: "pie"
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480
          }
        ]
      };
      this.seriesTR = [];
      this.chartPayment = {
        colors: [
          "#014713",
          "#5d5fd8",
          "#08fc5c",
          "#19305c",
          "#05abf0",
          "#1d73ec"
        ],
        chart: {
          width: 380,
          type: "pie"
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480
          }
        ]
      };
      this.seriesPayment = [];
      const payload = {
        start_date: dayjs(this.start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_date: dayjs(this.end_date).format("YYYY-MM-DD HH:mm:ss")
      };
      await service.getOrdersDashboard(payload).then(async res => {
        await service.getMallDashboard(payload).then(response => {
          this.mallDashboardData = response.data.data;
        });
        if (res.data.status === "success") {
          this.loading = false;
          this.dashboardData = res.data.data;
          res.data.data.payment.map(payment => {
            this.seriesPayment.push(payment.payCount);
            this.chartPayment.labels.push(payment.pay_type_text);
          });
          res.data.data.category.map(category => {
            this.chartTR.labels.push(category.service_category);
            this.seriesTR.push(category.tr);
          });
          this.loading = false;
        }
      });
    },
    async onexport(text) {
      let reportXlsx = [];
      let fileName = "";
      let days = [];
      let mDays = [];
      const start_date = dayjs(this.start_date).format("YYYY-MM-DD HH:mm:ss");
      const end_date = dayjs(this.end_date).format("YYYY-MM-DD HH:mm:ss");
      await service
        .downloadOrdersDashboard(start_date, end_date, text)
        .then(res => {
          if (text === "category") {
            reportXlsx = XLSX.utils.json_to_sheet(res.data.data);
            fileName = "Category";
          }
          if (text === "time") {
            reportXlsx = XLSX.utils.json_to_sheet(res.data.data);
            fileName = "time";
          }
          if (text === "sales") {
            days.push(
              res.data.data.W,
              res.data.data.M,
              res.data.data.Y,
              res.data.data.Total
            );
            mDays.push(
              res.data.data.mall_w,
              res.data.data.mall_m,
              res.data.data.mall_y,
              res.data.data.mall_total
            );
            res.data.data.sales_data.push(["W/W", "M/M", "Y/Y", "Total"]);
            res.data.data.sales_data.push(days);
            res.data.data.sales_data.push([
              "mall_w",
              "mall_m",
              "mall_y",
              "Mall_Total"
            ]);
            res.data.data.sales_data.push(mDays);
            reportXlsx = XLSX.utils.json_to_sheet(res.data.data.sales_data);
            fileName = "Sales";
          }
          if (text === "tr") {
            days.push(
              res.data.data.W,
              res.data.data.M,
              res.data.data.Y,
              res.data.data.Total
            );
            mDays.push(
              res.data.data.mall_w,
              res.data.data.mall_m,
              res.data.data.mall_y
            );
            res.data.data.tr_data.push(["W/W", "M/M", "Y/Y"]);
            res.data.data.tr_data.push(days);
            res.data.data.tr_data.push(["mall_w", "mall_m", "mall_y"]);
            res.data.data.tr_data.push(mDays);
            reportXlsx = XLSX.utils.json_to_sheet(res.data.data.tr_data);
            fileName = "Transaction";
          }
          if (text === "payment") {
            reportXlsx = XLSX.utils.json_to_sheet(res.data.data);
            fileName = "Payment";
          }
          let wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, reportXlsx, fileName);
          XLSX.writeFile(wb, fileName + ".xlsx");
        });
    }
  }
};
</script>
<style scoped>
.card {
  width: 100%;
}

.card-container {
  width: 100%;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.card-container:hover {
  border: 1px solid orange;
  transition: 1.3s;
}
.chart {
  box-shadow: 5px 2px 5px 0px #888888;
}
.minWidth {
  width: 33%;
}
.chartContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
